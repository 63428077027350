<template>
  <div class="mb-3">
    <div v-show="showIndex==0">
      <CRow class="mb-3">
        <CCol sm="12" lg="12" xl="12" class="d-flex align-items-center justify-content-start">
          <h6 class="mt-2"><CIcon name='cil-list' size='lg' class="titulo-icono" /> {{ `${this.$t('label.blsInventory')}`}} </h6>
        </CCol>
      </CRow>
      <CRow class="mt-2">
        <CCol sm="12">
          <dataTableExtended
            class="align-center-row-datatable"
            :items="computedListContainer"
            :fields="fields"
            :items-per-page="5"
            column-filter
            pagination
            :table-filter="tableText.tableFilterText"
            :items-per-page-select="tableText.itemsPerPageText"
            :noItemsView="tableText.noItemsViewText"
            sorter
          >
            <template #YardCargoBlStatus="{ item }">
              <td class="cell-center text-center">
                <CBadge :color="getBadge(item.YardCargoBlStatus)">
                  {{ item.YardCargoBlStatus }}
                </CBadge>
              </td>
            </template>
            <template #BlStatus="{ item }">
              <td class="cell-center text-center">
                <CBadge :color="getBadge(item.BlStatus)">
                  {{ item.BlStatus }}
                </CBadge>
              </td>
            </template>
            <template #options="{ item }">
              <td class="text-center">
                <CButton
                  square
                  size="sm"
                  color="watch"
                  class="d-flex align-items-center"
                  @click="toggleAdd(item)"
                  v-c-tooltip="{
                    content: `${$t('label.see')} ${$t('label.bl')}`,
                    placement: 'top-start'
                  }"
                >
                  <CIcon name='cil-list' />
                </CButton>
              </td>
            </template>
            <template #Weight="{ item }">
              <td class="text-center">
                {{ NumberFormat(item.Weight, 2) }}
              </td>
            </template>
          </dataTableExtended>
        </CCol>
      </CRow>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import General from '@/_mixins/general';
import { DateFormater, NumberFormater } from '@/_helpers/funciones';

//data
function data() {
  return {
    Items: [],
    dataContainer: [],
    showIndex: 0,
  };
}

//methods
function NumberFormat(number, decimal) {
  return NumberFormater.formatNumber(number, decimal);
}

async function getBlVisitReception(loading) {
  this.$store.state.yardManagement.loading = true;
  await this.$http.get('BlCargo-list-by-YardId', { BlCargoJson: {YardId: this.YardId} })
    .then((response) => {
      let List = response.data.data;
      this.dataContainer = List&&List.length!=0 ? List : [];
    })
    .catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
    .finally(() => {
      this.$store.state.yardManagement.loading = loading;
    });
}

async function Update(loading) {
  await this.getBlVisitReception(loading);
}

function toggleAdd(item) {
  this.$store.state.yardManagement.FgReception = true;
  this.$store.state.yardManagement.blSelected = item.BlCargoId;
}

function getBadge(status) {
  switch (status) {
    case 'INACTIVO':
      return 'danger'
    default:
      return 'success'
  }
}

//computed
function computedListContainer() {
  let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  return this.dataContainer.map((item, index) => {
    return {
      ...item,
      Nro: index+1,
      TpCargoName: item[`TpCargoName${_lang}`] ?? 'N/A',
      PortActivityName: item[`PortActivityName${_lang}`] ?? 'N/A',
      VesselVoyage: `${item.VesselName} - ${item.Voyage}`,
      Ata: item.Ata ? DateFormater.formatDateTimeWithSlash(item.Ata) : 'N/A',
      Atd: item.Atd ? DateFormater.formatDateTimeWithSlash(item.Atd) : 'N/A',
      FirstEventName: item[`FirstEventName${_lang}`] ?? 'N/A',
      FirstEventDate: item.FirstEventDate ? DateFormater.formatDateTimeWithSlash(item.FirstEventDate) : '',
      LastEventName: item[`LastEventName${_lang}`] ?? 'N/A',
      LastEventDate: item.LastEventDate ? DateFormater.formatDateTimeWithSlash(item.LastEventDate) : '',
      YardCargoBlStatus: item[`YardCargoBlStatus${_lang}`] ?? 'N/A',
      BlStatus: item[`BlStatus${_lang}`] ?? 'N/A',
      TpBlName: item.TpBlName ?? '',
      WeigthInYard: item.WeigthInYard ? NumberFormater.formatNumber(item.WeigthInYard, 2) : '0,00',
      DocumentCode: item.DocumentCode ?? '',
      ClientName: item.ClientName ?? '',
      DaysInYard: item.DaysInYard ?? '',
      MasterBlNro: item.MasterBlNro ?? '',
      _classes: 'color-gradient',
      _style: `background:${item.YardCargoBlStatusColor}`
    };
  })
}

function fields(){ 
  return [
    { key: 'options', label: '', _style: 'min-width:45px;', sorter: false, filter: false },
    { key: 'Nro', label: '#', _style: 'width:50px', _classes: 'text-center', filter: false },
    { key: 'BlNro', label: this.$t('label.bl'),  _style:'min-width:180px;', _classes:" text-center" , sorter: true, filter: true},
    { key: 'MasterBlNro', label: this.$t('label.Master'),  _style:'min-width:180px;', _classes:" text-center" , sorter: true, filter: true},
    { key: 'TpBlName', label: this.$t('label.blType'),  _classes: 'text-uppercase text-center', _style: 'min-width:140px;', sorter: true, filter: true},
    { key: 'DocumentCode', label: this.$t('label.Acta'),  _classes: 'text-uppercase text-center', _style: 'min-width:180px;', sorter: true, filter: true},
    { key: 'ClientName', label: this.$t('label.client'), _style:'min-width:200px', _classes:'text-uppercase text-center center-cell' },
    { key: 'TpCargoName',label: this.$t('label.group'), _style:'min-width:230px;', _classes:'text-center' },
    { key: 'PortActivityName',label: this.$t('label.activity'), _style:'min-width:230px;', _classes:'text-center' },
    { key: 'VesselVoyage', label: this.$t('label.visit'),  _classes: 'text-uppercase text-center', _style: 'min-width:235px;', sorter: true, filter: true},
    { key: 'Ata', label: 'ATA',  _classes: 'text-uppercase text-center', _style: 'min-width:150px;', sorter: true, filter: true},
    { key: 'QuantityReceived', label: this.$t('label.CertifiedReceived'),  _classes: 'text-uppercase text-center', _style: 'min-width:235px;', sorter: true, filter: true},
    { key: 'WeigthInYard', label: `${this.$t('label.weight')} (KGM)`,  _classes: 'text-uppercase text-center', _style: 'min-width:180px;', sorter: true, filter: true},
    { key: 'DaysInYard', label: `${this.$t('label.daysInYard')}`,  _classes: 'text-uppercase text-center', _style: 'min-width:160px;', sorter: true, filter: true},
    { key: 'FirstEventName', label: this.$t('label.firstEvent'),  _classes: 'text-uppercase text-center', _style: 'min-width:200px;', sorter: true, filter: true},
    { key: 'FirstEventDate', label: this.$t('label.dateFierstEvent'),  _classes: 'text-uppercase text-center', _style: 'min-width:200px;', sorter: true, filter: true},
    { key: 'LastEventName', label: this.$t('label.LastEvent'),  _classes: 'text-uppercase text-center', _style: 'min-width:200px;', sorter: true, filter: true},
    { key: 'LastEventDate', label: this.$t('label.DateLastEvent'),  _classes: 'text-uppercase text-center', _style: 'min-width:200px;', sorter: true, filter: true},
    { key: 'YardCargoBlStatus', label: this.$t('label.statusYardWarehouse'),  _classes: 'text-uppercase text-center', _style: 'min-width:260px;', sorter: true, filter: true},  
    { key: 'BlStatus', label: this.$t('label.statusCertificate'),  _classes: 'text-uppercase text-center', _style: 'min-width:260px;', sorter: true, filter: true},  
  ];
}



export default{
  name: 'blsInWarehouse',
  data,
  mixins: [General],
  methods: {
    toggleAdd,
    getBlVisitReception,
    Update,
    NumberFormat,
    getBadge
  },
  computed: {
    fields,
    computedListContainer,
    ...mapState({
      globalSelectBl: state => state.yardManagement.globalSelectBl,
      CompanyBranchId: state => state.auth.branch.CompanyBranchId,
      YardId: state => state.yardManagement.yardData.YardId,
      FgPositioning: state => state.yardManagement.FgPositioning,
      dropItemMovementList: state => state.yardManagement.dropItemMovementList,
      dataBl: state => state.yardManagement.dataBl,
      FgBlWarehouse: state => state.yardManagement.FgBlWarehouse,
    })
  },
  watch:{
    dropItemMovementList: function (val) {
      if (val==1) {
        this.showIndex = 0;
        this.dataContainer = [];
        this.getBlVisitReception(false);
      }
    },
    FgBlWarehouse: function (val) {
      if (val && this.dropItemMovementList == 1) {
        this.getBlVisitReception(false);
      }
      this.$store.state.yardManagement.FgBlWarehouse = false;
    }

  }
}
</script>